import React, { useState } from 'react';

import Warning from './icons/Warning';

const Button = ({ title, theme, loading, error, disabled, onClick }) => {

    const light = `flex flex-row w-full h-12 sm:h-9 items-center justify-center py-4 sm:py-2 px-4 rounded-xl border-[0.5px] border-forbidden-green dark:border-forbidden-cream bg-white text-forbidden-green active:dark:text-forbidden-green dark:text-forbidden-cream dark:bg-forbidden-green hover:bg-forbidden-cream/50 active:bg-forbidden-cream transition-colors duration-400`;
    const dark = "flex flex-row w-full h-12 sm:h-9 items-center justify-center py-4 sm:py-2 px-4 rounded-xl border-[0.5px] border-white active:border-forbidden-green bg-forbidden-green dark:bg-forbidden-cream text-white active:text-forbidden-green dark:text-forbidden-green hover:bg-forbidden-green/90 dark:hover:bg-forbidden-cream/90 active:bg-forbidden-cream transition-colors duration-400";

    // Function to parse the title and replace <sup> tags with React elements
    const parseTitle = (title) => {
        // Create a new div element to hold the HTML string
        const div = document.createElement('div');
        div.innerHTML = title;

        // Replace <sup> tags with React superscript elements
        const elements = Array.from(div.childNodes).map((node, index) => {
            if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'sup') {
                return <sup key={index}>{node.textContent}</sup>;
            } else {
                return node.textContent || node.nodeValue;
            }
        });

        return elements;
    };

    return (
        <div class="flex flex-col w-full h-full items-center justify-center space-y-2">
            <button
                disabled={disabled}
                class={theme === "dark" ? dark : light}
                onClick={onClick}
            >
                {loading ? (
                    <>
                        <svg fill="none" viewBox="0 0 24 24" strokeWidth={0} stroke="currentColor" className="w-4 h-4 animate-spin">
                            <circle class="opacity-25" cx="12" cy="12" />
                            <path class="opacity-100" fill={theme === 'light' ? '#FFFFFF' : '#FFFFFF'} d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                    </>
                ) : (
                    <p class="text-sm font-new-spirit-light">{parseTitle(title)}</p>
                )}
            </button>
            {error && (
                <div class="flex flex-row w-full items-center justify-start space-x-2 text-forbidden-red">
                    <Warning />
                    <p class="font-new-spirit-light-condensed text-sm">{error}</p>
                </div>
            )}
        </div>
    );
};

export default Button;