import React, { useState } from 'react';

const Header = () => {

    const [page, setPage] = useState(window.location.pathname);

    return (
        <div class={`flex flex-row items-center justify-between ${page == '/' && 'animate-fade-down animate-delay-300'}`}>
            <div></div>

            <div class="flex flex-row items-center justify-center relative">
                <a href="/" class="font-new-spirit-medium-condensed text-2xl text-forbidden-green dark:text-forbidden-cream">Forbidden</a>
            </div>

            <div></div>
        </div>
    );
};

export default Header;