import React, { useState } from 'react';

/* Import Components */
import Button from '../components/Button.js';

const Error = () => {

    const [page, setPage] = useState(window.location.pathname);

    return (
        <div class="flex flex-1 flex-col h-full w-full items-center justify-center space-y-4 animate-fade animate-duration-700">
            <div class="flex flex-col items-center justify-center relative">
                <a class="font-new-spirit-medium-condensed text-5xl text-forbidden-green dark:text-forbidden-cream">404</a>
                <p class="font-new-spirit-light-condensed text-xs uppercase text-forbidden-green dark:text-forbidden-cream whitespace-nowrap">Page Not Found</p>
            </div>

            <div class={`flex flex-row items-center justify-between ${page == '/' && 'animate-fade-up animate-delay-300'}`}>
                <div></div>

                <div class="flex md:flex-row flex-col items-center justify-center md:space-x-2 space-x-0 md:space-y-0 space-y-2">
                    <Button 
                        title="Go home"
                        onClick={() => window.location.href = "/"}
                    />
                </div>

                <div></div>
            </div>
        </div>
    );
};

export default Error;