import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

/* Import Contexts */
import { OrderContextProvider } from './contexts/OrderContext';

/* Import Pages */
import Home from './pages/Home';
import About from './pages/About';
import Order from './pages/Order';
import Confirmation from './pages/Confirmation';
import Success from './pages/Success'
import Error from './pages/Error';

/* Import Components */
import Header from './components/Header';

const App = () => {

    return (
        <Router>
            <OrderContextProvider>
                <Routes>
                    <Route
                        path="/" 
                        element={
                            <div class="flex flex-col w-screen h-screen min-h-screen items-center justify-center p-4 space-y-4 dark:bg-forbidden-green">
                                <Header />
                                <Home />
                            </div>
                        }
                    />
                    <Route
                        path="/about" 
                        element={
                            <div class="flex flex-col w-screen h-screen min-h-screen items-center justify-center p-4 space-y-4 dark:bg-forbidden-green">
                                <Header />
                                <About />
                            </div>
                        }
                    />
                    <Route
                        path="/order" 
                        element={
                            <div class="flex flex-col w-screen h-screen min-h-screen overflow-hidden items-center justify-center p-4 space-y-4 dark:bg-forbidden-green">
                                <Header />
                                <Order />
                            </div>
                        }
                    />
                    <Route
                        path="/order/:orderId/confirm" 
                        element={
                            <div class="flex flex-col w-screen h-screen min-h-screen overflow-hidden items-center justify-center p-4 space-y-4 dark:bg-forbidden-green">
                                <Header />
                                <Confirmation />
                            </div>
                        }
                    />
                    <Route
                        path="/order/:orderId/success" 
                        element={
                            <div class="flex flex-col w-screen h-screen min-h-screen overflow-hidden items-center justify-center p-4 space-y-4 dark:bg-forbidden-green">
                                <Header />
                                <Success />
                            </div>
                        }
                    />
                    <Route
                        path="/*" 
                        element={
                            <div class="flex flex-col w-screen h-screen items-center justify-center p-4 space-y-4 dark:bg-forbidden-green">
                                <Header />
                                <Error />
                            </div>
                        }
                    />
                </Routes>
            </OrderContextProvider> 
        </Router>                
    );
};

export default App;