import React, { useState } from 'react';

import ForbiddenTrademarks from '../assets/about/trademarks.png';
import MissionStatement from '../assets/about/mission_statement.svg';
import AnAppleSauced from '../assets/about/an_apple_sauced.svg';
import RebirthDrivesProgress from '../assets/about/rebirth_drives_progress.png';
import CorporateResponsibility from '../assets/about/corporate_responsibility.svg';

/* Import Components */
import Button from '../components/Button.js';

const Home = () => {

    const [page, setPage] = useState(window.location.pathname);

    return (
        <div class="flex flex-1 flex-col items-center justify-center border">
            { /* First Card */ }
            <div class="flex flex-col items-center justify-center bg-forbidden-green w-full">

            </div>
            
            { /* Second Card */ }
            <div class="flex flex-1 flex-row h-full w-full items-center justify-center animate-fade animate-duration-700">
                <div className="items-center justify-center animate-fade animate-delay-75">
                    <img src={MissionStatement} alt="Mission Statement" class="w-full h-full" />
                </div>
                <div className="items-center justify-center animate-fade animate-delay-150">
                    <img src={AnAppleSauced} alt="An Apple Sauced" class="w-full h-full" />
                </div>
                <div className="items-center justify-center animate-fade animate-delay-300">
                    <img src={CorporateResponsibility} alt="Corporate Responsibility" class="w-full h-full" />
                </div>
                
            </div>

        </div>
        
    );
};

export default Home;