import React from 'react';

const Input = ({ type, placeholder, value, onChange }) => {
    return (
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className="flex w-full h-full px-4 py-4 sm:py-2 rounded text-sm dark:bg-forbidden-green font-new-spirit-light text-forbidden-green dark:text-forbidden-cream border-[0.5px] border-forbidden-green dark:border-forbidden-cream focus:outline-none transition-colors duration-400"
        />
    );
};

export default Input;