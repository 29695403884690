import React, { useState } from 'react';

/* Import Components */
import Button from '../components/Button.js';

const Success = () => {

    const [page, setPage] = useState(window.location.pathname);

    return (
        <div class="flex flex-1 flex-col h-full w-full items-center justify-center space-y-4 animate-fade-left animate-duration-700">
            <div class="flex max-w-xl flex-col items-start justify-center relative space-y-4">
                <a class="font-new-kansas-medium text-7xl text-forbidden-green dark:text-forbidden-cream">your order has been placed!</a>
                <p class="font-new-spirit-light-condensed text-sm uppercase text-forbidden-grey dark:text-forbidden-cream whitespace-nowrap">We can't wait to get you your applesauce. We'll be in touch soon.</p>
            </div>

            <div class={`flex flex-row items-center justify-between ${page == '/' && 'animate-fade-up animate-delay-300'}`}>
                <div></div>

                <div class="flex md:flex-row flex-col items-center justify-center md:space-x-2 space-x-0 md:space-y-0 space-y-2">
                    <Button 
                        title="Go home"
                        onClick={() => window.location.href = "/"}
                    />
                </div>

                <div></div>
            </div>
        </div>
    );
};

export default Success;